<template>
    <div class="product-page vr-chem-lab-page">
        <h1 class="page-title">Физика.Магнетизм</h1>
        <div class="page-hero">
            <iframe
                src="https://vk.com/video_ext.php?oid=-200137374&id=456239027&hash=d0e8fb71a20379de&hd=2"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>
        <section class="page-section">
            <h2 class="section__title">Физика.Магнетизм</h2>
            <p class="section__text">
                VR-курс по физике «Магнетизм» – обучающий комплекс для подготовки учащихся 8-9 классов по темам
                магнетизма и электромагнитной индукции. Позволяет изучить явления в пространстве, понять связь видимых
                действий с невидимыми феноменами. Благодаря системе микроуроков позволяет гибко организовывать учебный
                процесс при первичном изучении материала, повторении и подготовке к экзамену.
            </p>
            <p class="section__text">Приложение разработано в сотрудничестве с компанией «Модум Лаб»</p>
        </section>
        <section class="page-section page-section--dark page-section--no-padding">
            <h2 class="section__title">Фотогалерея</h2>
            <vr-slider class="product-page__gallery" :items="gallery">
                <template #default="{ item, keyValue }">
                    <div class="gallery__item" :key="keyValue">
                        <img class="item__image" :src="item.url" />
                    </div>
                </template>
            </vr-slider>
        </section>
        <section class="page-section page-section--light vr-chem-lab-page__section">
            <h2 class="section__title">{{ title }}:</h2>
            <template v-for="(section, secIdx) in list.slice(0, 1)">
                <h4 :key="`slice-section-header-${secIdx}`">{{ section.name }}</h4>
                <ol :key="`slice-section-list-${secIdx}`" class="section__ordered-list">
                    <li v-for="(lesson, lesIdx) in section.lessons" :key="`lesson-${secIdx}-${lesIdx}`">
                        {{ lesson }}
                    </li>
                </ol>
            </template>
            <vr-button-link @click="openSimpleModal">+ Показать все</vr-button-link>
        </section>
        <section class="page-section">
            <h2 class="section__title">Технические требования</h2>
            <p>
                Продукт спроектирован для использования с устройствами виртуальной реальности, такими как Vive Focus /
                Focus plus / Focus 3, HTC Vive / Vive Pro и аналогами. Список поддерживаемого оборудования расширяется.
            </p>
        </section>
        <section class="page-section page-section--dark">
            <h2 class="section__title">Цена продукта</h2>
            <product-calculator :licencesData="licencesData" :modalText="modalText" class="product-page__calculator" />
        </section>
        <section class="page-section">
            <h2 class="section__title">Подробнее о продукте</h2>
            <a class="section__link" :href="require('@/assets/img/views/magnetism/magnetism-rao.jpg')" target="_blank"
                >+ заключение РАО</a
            >
            <a class="section__link" :href="require('@/assets/pdf/Физика.Магнетизм.pdf')" target="_blank"
                >+ Печатные материалы о продукте</a
            >
        </section>
        <simple-modal size="small" ref="SimpleModal">
            <h3>{{ title }}</h3>
            <template v-for="(section, secIdx) in list">
                <h5 :key="`section-header-${secIdx}`">{{ section.name }}</h5>
                <ol :key="`section-list-${secIdx}`">
                    <li v-for="(lesson, lesIdx) in section.lessons" :key="`lesson-${secIdx}-${lesIdx}`">
                        {{ lesson }}
                    </li>
                </ol>
            </template>
        </simple-modal>
    </div>
</template>

<script>
import VRSlider from '@/components/VRSlider';
import ProductCalculator from '@/components/ProductCalculator';
import SimpleModal from '@/components/modals/SimpleModal.vue';
import VRButtonLink from '@/components/buttons/VRButtonLink.vue';

export default {
    data() {
        return {
            gallery: Array.from({ length: 5 }).map((_, idx) => ({
                url: require(`@/assets/img/views/magnetism/frame${idx + 1}.jpg`),
            })),
            title: 'Доступные уроки',
            list: [
                {
                    name: 'Теория',
                    lessons: [
                        'Постоянные магниты, опыт Ампера, опыт Эрстеда',
                        'Правило правой руки, правило правой руки для катушки (соленоида)',
                        'Сила Ампера, правило левой руки и сила Лоренца',
                        'Опыт Фарадея, правило Ленца',
                    ],
                },
                {
                    name: 'Практика',
                    lessons: [
                        'Правило правой руки',
                        'Опыт Эрстеда',
                        'Правило левой руки',
                        'Сила Лоренца',
                        'Опыт Фарадей',
                    ],
                },
                {
                    name: 'Тренировка',
                    lessons: [
                        'Правило правой руки',
                        'Опыт Эрстеда',
                        'Правило левой руки',
                        'Сила Лоренца',
                        'Опыт Фарадей',
                    ],
                },
            ],
            licencesData: [
                {
                    key: 'unlimited',
                    period: '"вечная"',
                    price: 48900,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 48900,
                        },
                    ],
                },
            ],
            modalText:
                'Покупка лицензии на программное обеспечение означает полный доступ ко всему его функционалу на время действия лицензии, включая все обновления приложения, методических материалов и т.д., которые будут выпущены за время действия лицензии. Лицензия на ПО «Физика.Магнетизм» продается без ограничения времени использования. Лицензия приобретается на одно рабочее место (автономный шлем или компьютер со стационарным VR-шлемом). Цена одной лицензии меняется в зависимости от количества приобретаемых единоразово лицензий. Для приблизительных расчетов вы можете воспользоваться встроенным калькулятором, а так же запросить более подробную информацию, написав на адрес электронной почты eduvr@vrnti.ru. Текущая ценовая политика актуальна до 31.08.2022.',
            stationaryHeadsets: ['Focus Plus', 'Focus 3', 'Pico Neo', 'Oculus Qwest 2'],
            standaloneHeadset: [
                'HTC Vive',
                'Vive Pro',
                'Vive Pro Eye',
                'Vive Cosmos',
                'Oculus Rift',
                'HP Reverb',
                'Dell visor',
                'Samsung HMD Odyssey+',
                'Acer AH10',
            ],
        };
    },
    methods: {
        openSimpleModal() {
            this.$refs.SimpleModal.showModal();
        },
    },
    components: {
        ProductCalculator,
        'vr-slider': VRSlider,
        'simple-modal': SimpleModal,
        'vr-button-link': VRButtonLink,
    },
};
</script>
