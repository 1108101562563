<template>
    <div class="product-page varvara-page">
        <h1 class="page-title">VARVARA</h1>
        <div class="page-hero">
            <iframe
                src="https://vk.com/video_ext.php?oid=-200137374&id=456239030&hash=15bb7adf85bfa30a&hd=2"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>
        <section class="page-section">
            <h2 class="section__title">VARVARA</h2>
            <p class="section__text">
                Российский диалоговый тренажер для практики английского языка с использованием виртуальной реальности и
                технологий распознавания речи, рассчитанный на начальные уровни изучения языка. Продукт может быть
                использован в первый-второй год изучения английского или для работы с отстающими учениками. В
                виртуальной реальности проигрываются интерактивные симуляции ситуаций из жизни, например, покупка зимней
                одежды, заказ в кафе, разговор о здоровом образе жизни, диалог с незнакомцем на улице и многие другие.
            </p>
            <p class="section__text">Приложение разработано в сотрудничестве с компанией «Виар Суперсоник»</p>
        </section>
        <section class="page-section page-section--dark page-section--no-padding">
            <h2 class="section__title">Фотогалерея</h2>
            <vr-slider class="product-page__gallery" :items="gallery">
                <template #default="{ item, keyValue }">
                    <div class="gallery__item" :key="keyValue">
                        <img class="item__image" :src="item.url" />
                    </div>
                </template>
            </vr-slider>
        </section>
        <section class="page-section page-section--light vr-chem-lab-page__section">
            <h2 class="section__title">{{ title }}:</h2>
            <ol class="section__ordered-list">
                <li v-for="(item, idx) in list.slice(0, 5)" :key="`list-slice-item-${idx}`" class="ordered-list__item">
                    {{ item }}
                </li>
            </ol>
            <vr-button-link @click="openSimpleModal">+ Показать все</vr-button-link>
        </section>
        <section class="page-section">
            <h2 class="section__title">Технические требования</h2>
            <p>Для стабильной стабильной работы приложения требуется:</p>
            <ul>
                <li>«VR Ready» компьютер</li>
                <li>стационарный шлем виртуальной реальности</li>
                <li>подключение к интернету на скорости не менее 10&nbsp;МБит&nbsp;/&nbsp;с</li>
            </ul>
            <p>либо</p>
            <ul>
                <li>автономный шлем виртуальной реальности</li>
                <li>подключение к интернету на скорости не менее 10&nbsp;МБит&nbsp;/&nbsp;с</li>
            </ul>
            <p>Список поддерживаемых шлемов виртуальной реальности приведен в таблице.</p>
            <table class="section__table">
                <tbody>
                    <tr>
                        <td>Список стационарных шлемов</td>
                        <td>Список автономных шлемов</td>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(headset, idx) in stationaryHeadsets" :key="`stationaryHeadset-${idx}`">
                                    {{ headset }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li v-for="(headset, idx) in standaloneHeadset" :key="`standaloneHeadset-${idx}`">
                                    {{ headset }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        <section class="page-section page-section--dark">
            <h2 class="section__title">Цена продукта</h2>
            <product-calculator :licencesData="licencesData" :modalText="modalText" class="product-page__calculator" />
        </section>
        <section class="page-section">
            <h2 class="section__title">Подробнее о продукте</h2>
            <a class="section__link" :href="require('@/assets/img/views/varvara/varvara-rao.jpg')" target="_blank"
                >+ заключение РАО</a
            >
            <a class="section__link" :href="require('@/assets/pdf/VARVARA.pdf')" target="_blank"
                >+ Печатные материалы о продукте</a
            >
        </section>
        <simple-modal size="small" ref="CalculatorSimpleModal">
            <h3>{{ title }}</h3>
            <ol>
                <li v-for="(item, idx) in list" :key="`task-${idx}`">{{ item }}</li>
            </ol>
        </simple-modal>
    </div>
</template>

<script>
import VRSlider from '@/components/VRSlider';
import ProductCalculator from '@/components/ProductCalculator';
import SimpleModal from '@/components/modals/SimpleModal.vue';
import VRButtonLink from '@/components/buttons/VRButtonLink.vue';

export default {
    data() {
        return {
            gallery: Array.from({ length: 6 }).map((_, idx) => ({
                url: require(`@/assets/img/views/varvara/frame${idx + 1}.jpg`),
            })),
            title: 'Темы диалогов',
            list: [
                'At the gym',
                'Buying clothes for Alaska',
                'Checking in',
                'Discussing art',
                'Emotions in the gallery',
                'Graffiti',
                'Have you ever',
                'Healthy lifestyle',
                'Introducing yourself',
                'Making suggestions',
                'New Year',
                'Ordering food',
                'Ordering pizza',
                'Receiving complaints',
                'Returning items',
                'School concert',
                "School day's routine",
                'Serving a customer',
                'ЕГЭ, задание 3. Формат 2022',
                'Taking an order',
                'Talking about a party',
                'Talking to strangers',
                'Usually but today',
                'What time is it now?',
                "What's on special",
                'ЕГЭ. Задание 2. Досуг',
                'ЕГЭ. Задание 2. Путешествия',
                'Косвенная речь',
                'Обсуждение достопримечательностей родной страны',
                'Что ты делаешь сегодня вечером?',
            ],
                        licencesData: [
                {
                    key: 'one-year',
                    period: 'на срок 1 год',
                    price: 35000,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 35000,
                        },
                    ],
                },
                {
                    key: 'two-years',
                    period: 'на срок 2 года',
                    price: 70000,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 70000,
                        },
                    ],
                },
                {
                    key: 'three-years',
                    period: 'на срок 3 года',
                    price: 105000,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 105000,
                        },
                    ],
                },
            ],
            modalText:
                'Покупка лицензии на программное обеспечение означает полный доступ ко всему его функционалу на время действия лицензии, включая все обновления приложения, методических материалов и т.д., которые будут выпущены за время действия лицензии. Лицензия на ПО «»VARVARA может быть приобретена на 1, 2 или 3 года. Лицензия приобретается на одно рабочее место (автономный шлем или компьютер со стационарным VR-шлемом). Для приблизительных расчетов вы можете воспользоваться встроенным калькулятором, а так же запросить более подробную информацию, написав на адрес электронной почты eduvr@vrnti.ru. Текущая ценовая политика актуальна до 31.08.2022.',
            stationaryHeadsets: ['HTC Vive', 'Vive Pro', 'Vive Pro Eye', 'Vive Cosmo'],
            standaloneHeadset: ['HTC Vive Focus', 'HTC Vive Focus Plus', 'Pico G2 4'],
        };
    },
    methods: {
        openSimpleModal() {
            this.$refs.CalculatorSimpleModal.showModal();
        },
    },
    components: {
        ProductCalculator,
        'vr-slider': VRSlider,
        'simple-modal': SimpleModal,
        'vr-button-link': VRButtonLink,
    },
};
</script>
