<template>
    <div class="product-page vr-chem-lab-page">
        <h1 class="page-title">VR Chemistry Lab - Виртуальная химическая лаборатория</h1>
        <div class="page-hero">
            <iframe
                src="https://vk.com/video_ext.php?oid=-200137374&id=456239028&hash=bdda017eb1df13b3&hd=2"
                allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                frameborder="0"
                allowfullscreen
            ></iframe>
        </div>
        <section class="page-section">
            <h2 class="section__title">VR Chemistry Lab</h2>
            <p class="section__text">
                Это виртуальная химическая лаборатория, позволяющая выполнять эксперименты, которые нет возможности дать
                учащимся из соображений безопасности, материальных ограничений или необходимости проводить занятия
                дистанционно.
            </p>
            <p class="section__text">
                Свобода действий пользователя и достоверность результатов проводимых реакций обеспечивается специально
                разработанной химико-физической моделью.
            </p>
            <p class="section__text">
                Готовые задания охватывают основные темы неорганической химии и ориентированы на использование в
                основном и дополнительном образовании в 8, 9 и 11-го классах.
            </p>
            <p class="section__text">
                Продукт прошел экспертизу РАО и входит в реестр Российского программного обеспечения.
            </p>
            <p class="section__text">Приложение разработано в сотрудничестве с компанией «СТЕМ-игры»</p>
        </section>
        <section class="page-section page-section--dark page-section--no-padding">
            <h2 class="section__title">Фотогалерея</h2>
            <vr-slider class="product-page__gallery" :items="gallery">
                <template #default="{ item, keyValue }">
                    <div class="gallery__item" :key="keyValue">
                        <img class="item__image" :src="item.url" />
                    </div>
                </template>
            </vr-slider>
        </section>
        <section class="page-section page-section--light vr-chem-lab-page__section">
            <h2 class="section__title">{{ title }}:</h2>
            <ol class="section__ordered-list">
                <li v-for="(item, idx) in list.slice(0, 5)" :key="`list-slice-item-${idx}`" class="ordered-list__item">
                    {{ item }}
                </li>
            </ol>
            <vr-button-link @click="openSimpleModal">+ Показать все</vr-button-link>
        </section>
        <section class="page-section">
            <h2 class="section__title">Технические требования</h2>
            <p>Для стабильной стабильной работы приложения требуется:</p>
            <ul>
                <li>«VR Ready» компьютер</li>
                <li>стационарный шлем виртуальной реальности</li>
                <li>подключение к интернету на скорости не менее 10&nbsp;МБит&nbsp;/&nbsp;с</li>
            </ul>
            <p>либо</p>
            <ul>
                <li>автономный шлем виртуальной реальности</li>
                <li>подключение к интернету на скорости не менее 10&nbsp;МБит&nbsp;/&nbsp;с</li>
            </ul>
            <p>Список поддерживаемых шлемов виртуальной реальности приведен в таблице.</p>
            <table class="section__table">
                <tbody>
                    <tr>
                        <td>Список стационарных шлемов</td>
                        <td>Список автономных шлемов</td>
                    </tr>
                    <tr>
                        <td>
                            <ul>
                                <li v-for="(headset, idx) in stationaryHeadsets" :key="`stationaryHeadset-${idx}`">
                                    {{ headset }}
                                </li>
                            </ul>
                        </td>
                        <td>
                            <ul>
                                <li v-for="(headset, idx) in standaloneHeadset" :key="`standaloneHeadset-${idx}`">
                                    {{ headset }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </section>
        <section class="page-section page-section--dark">
            <h2 class="section__title">Цена продукта</h2>
            <product-calculator :licencesData="licencesData" :modalText="modalText" class="product-page__calculator" />
        </section>
        <section class="page-section">
            <h2 class="section__title">Подробнее о продукте</h2>
            <a class="section__link" href="https://reestr.digital.gov.ru/reestr/322719/?sphrase_id=816947"
                >+ Ссылка на реестр российского ПО</a
            >
            <a class="section__link" :href="require('@/assets/img/views/vr-chem-lab/vrchemlab-rao.jpg')" target="_blank">+ заключение РАО</a>
            <a class="section__link" :href="require('@/assets/pdf/VR Chemistry Lab.pdf')" target="_blank">+ Печатные материалы о продукте</a>
        </section>
        <simple-modal size="small" ref="CalculatorSimpleModal">
            <h3>{{ title }}</h3><!--  -->
            <ol>
                <li v-for="(item, idx) in list" :key="`task-${idx}`">{{ item }}</li>
            </ol>
        </simple-modal>
    </div>
</template>

<script>
import VRSlider from '@/components/VRSlider';
import ProductCalculator from '@/components/ProductCalculator';
import SimpleModal from '@/components/modals/SimpleModal.vue';
import VRButtonLink from '@/components/buttons/VRButtonLink.vue';

export default {
    data() {
        return {
            gallery: Array.from({ length: 5 }).map((_, idx) => ({
                url: require(`@/assets/img/views/vr-chem-lab/frame${idx + 1}.jpg`),
            })),
            title: 'Доступные лабораторные работы',
            list: [
                'Получение амфотерного гидроксида',
                'Взаимодействие оксидов с водой',
                'Восстановительные свойства галогенидов',
                'Выбор возможных реакций (ОГЭ-24)',
                'Задача на распознавание солей (карбонаты)',
                'Задача на распознавание солей (фосфаты, нитраты, соли аммония)',
                'Качественный анализ',
                'Кислотно-основная классификация оксидов',
                'Кислотно-основные индикаторы',
                'Общие и специфические свойства кислот',
                'Общие и специфические свойства оснований',
                'Окислительные свойства простых веществ галогенов',
                'Оксиды металлов',
                'Определение анионов (H₂O, Na₂SO₄, Na₂S, NaCl и Na₂CO₃)',
                'Определение анионов (H₂O, Na₂SO₄, Na₂SO₃, Na₂S и Na₂CO₃)',
                'Определение катионов',
                'Пламенный анализ',
                'Различение солей серосодержащих кислот',
                'Свойства азотной кислоты, аммиака и солей аммония',
                'Свойства концентрированной серной кислоты и ее раствора',
                'Свойства некоторых соединений углерода и кремния',
                'Свойства оксида фосфора и фосфорной кислоты',
                'Свойства слабых кислот и оснований',
                'Свойства соединений железа и алюминия',
                'Свойства сульфидов',
                'Сокращенно-ионные уравнения',
                'Цепочки превращений',
                'Электрохимический ряд',
            ],
            licencesData: [
                {
                    key: 'one-year',
                    period: 'на срок 1 год',
                    price: 16200,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 16200,
                        },
                    ],
                },
                {
                    key: 'two-years',
                    period: 'на срок 2 года',
                    price: 29160,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 29160,
                        },
                    ],
                },
                {
                    key: 'unlimited',
                    period: '"вечная"',
                    price: 48600,
                    prices: [
                        {
                            start: 0,
                            end: Infinity,
                            price: 48600,
                        },
                    ],
                },
            ],
            modalText:
                'Покупка лицензии на программное обеспечение означает полный доступ ко всему его функционалу на время действия лицензии, включая все обновления приложения, методических материалов и т.д., которые будут выпущены за время действия лицензии. «Вечная» лицензия означает поддержку работы программного обеспечения на протяжении всего срока его жизни, но не менее 3 (трёх) лет с момента покупки лицензии. Лицензия приобретается на одно рабочее место (автономный шлем или компьютер со стационарным VR-шлемом). Цена одной лицензии меняется в зависимости от количества приобретаемых единоразово лицензий и срока действия приобретаемой лицензии. Для приблизительных расчетов вы можете воспользоваться встроенным калькулятором, а так же запросить более подробную информацию, написав на адрес электронной почты eduvr@vrnti.ru. Текущая ценовая политика актуальна до 31.08.2022.',
            stationaryHeadsets: ['Focus Plus', 'Focus 3', 'Pico Neo', 'Oculus Qwest 2'],
            standaloneHeadset: [
                'HTC Vive',
                'Vive Pro',
                'Vive Pro Eye',
                'Vive Cosmos',
                'Oculus Rift',
                'HP Reverb',
                'Dell visor',
                'Samsung HMD Odyssey+',
                'Acer AH10',
            ],
        };
    },
    methods: {
        openSimpleModal() {
            this.$refs.CalculatorSimpleModal.showModal();
        },
    },
    components: {
        ProductCalculator,
        'vr-slider': VRSlider,
        'simple-modal': SimpleModal,
        'vr-button-link': VRButtonLink,
    },
};
</script>
