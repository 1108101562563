<template>
    <div class="product-calculator">
        <div class="product-calculator__table">
            <div class="table__column" v-for="licence in licences" :key="`calc-column-${licence.key}`">
                <h5 class="column__title">
                    <span class="title__common">Одна лицензия</span>
                    <span class="title__specific">{{ licence.period }}</span>
                </h5>
                <p class="column__price">
                    <span class="price__value">{{ formatPrice(licence.price) }}</span>
                    <currency-icon class="price__icon" />
                </p>
                <p class="column__number">
                    <span class="number__label">Число лицензий</span>
                    <vr-number-input class="number__input" v-model="licence.number" min="0" />
                </p>
            </div>
        </div>
        <div class="product-calculator__footer">
            <p class="footer__total-label">Стоимость:</p>
            <div class="footer__total">
                <p class="total__price">
                    <span class="price__value">{{ formatPrice(total) }}</span>
                    <currency-icon class="price__icon" />
                </p>
            </div>
            <div class="footer__action">
                <vr-button @click="openFeedbackModal()" class="action__button">Купить</vr-button>
            </div>
            <div class="footer__action">
                <vr-button @click="openFeedbackModal({ requiredDemo: true })" outlined class="action__button"
                    >Запросить демо</vr-button
                >
            </div>
            <div class="footer__info">
                <p class="info__warning">Не является публичной офертой</p>
                <button v-if="modalText" @click="openSimpleModal" class="info__discounts">
                    *При заказе нескольких лицензий одновременно возможны скидки
                </button>
            </div>
        </div>
        <feedback-modal ref="calcFeedbackModal" size="medium" />
        <simple-modal v-if="modalText" ref="calcSimpleModal" size="small" align="center">
            <p>{{ modalText }}</p>
        </simple-modal>
    </div>
</template>

<script>
import CurrencyIcon from 'icons/CurrencyRub';
import VRNumberInput from '@/components/inputs/VRNumberInput';
import VRButton from '@/components/buttons/VRButton';
import FeedbackModal from '@/components/modals/FeedbackModal.vue';
import SimpleModal from '@/components/modals/SimpleModal.vue';

export default {
    components: {
        'vr-number-input': VRNumberInput,
        'vr-button': VRButton,
        CurrencyIcon,
        'feedback-modal': FeedbackModal,
        'simple-modal': SimpleModal,
    },
    data() {
        return {
            licences: this.licencesData.map(l => ({ ...l, number: 0 })),
        };
    },
    props: {
        licencesData: {
            type: Array,
            default: () => [],
        },
        modalText: {
            type: String,
            default: '',
        },
    },
    computed: {
        total() {
            return this.licences.reduce((acc, l) => (acc += l.number * l.price), 0);
        },
    },
    methods: {
        formatPrice(number) {
            if (!Number.isInteger(number)) {
                throw new Error('Price formatting supports integers only');
            }

            /*
				1. convert to string (10000 => '10000')
				2. split string into separate characters ('10000' => ['1', '0', '0', '0', '0'])
				3. reverse resulting array (['1', '0', '0', '0', '0'] => ['0', '0', '0', '0', '1'])
				4. traverse the array, adding a space after every element that goes after three others
						(['0', '0', '0', '0', '1'] => ['0', '0', '0', '0_', '1'], space represented by underscore)
				5. reverse array (['0', '0', '0', '0_', '1'] => ['1', '0_', '0', '0', '0'])
				6. join array into a string (['1', '0_', '0', '0', '0'] => '10_000')
			 */
            return number
                .toString()
                .split('')
                .reverse()
                .map((n, i) => n + (i > 0 && i % 3 === 0 ? ' ' : ''))
                .reverse()
                .join('');
        },
        openSimpleModal() {
            this.$refs.calcSimpleModal.showModal();
        },
        openFeedbackModal(initial) {
            this.$refs.calcFeedbackModal.showModal(initial);
        },
    },
    watch: {
        licences: {
            deep: true,
            handler: function () {
                this.licences.forEach(license => {
                    let interval = license.prices.find(
                        interval => interval.start <= license.number && license.number <= interval.end
                    );
                    license.price = interval.price;
                });
            },
        },
    },
};
</script>
